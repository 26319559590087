import React, { useEffect } from "react";
import Arrow from "../../Assets/Img/Arrow.png";
import "../home.css";
import { Link, useHistory } from "react-router-dom";
import individualleads from "../../Assets/Img/individualleads.png";
import BulkLeads from "../../Assets/Img/BulkLeads.png";
import LeadStatus from "../../Assets/Img/LeadStatus.svg";
import CommissionDetails from "../../Assets/Img/CommissionDetails.svg";
import call from "../../Assets/Img/call.png";
import logos_whatsapp_icon from "../../Assets/Img/logos_whatsapp_icon.png";
import AddHotLead from "../../Assets/Img/AddHotLead.jpg";
import AddBulk from "../../Assets/Img/AddBulk.jpg";
import LeadStatusCommission from "../../Assets/Img/LeadStatusCommission.jpg";
import StagePaymentWeb from "../../Assets/Img/StagePaymentWeb.svg";
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_KEY,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_KEY,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_KEY,
  appId: process.env.REACT_APP_FIREBASE_APPID_KEY,
  measurementId: process.env.REACT_APP_MEASUREMENTID_KEY,
};

firebase.initializeApp(firebaseConfig);
const Signingkyc = () => {
  const locations = window.location;
  const history = useHistory();

  useEffect(() => {
    window.scroll({
      top: 0,
    });

    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ screen: "Leads Related" })
    );
  }, []);
  useEffect(() => {
    firebase
      .analytics()
      .logEvent("Support Leads Related Screen", { from: "web" });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="single_suport">
        <div className="single_suport_container">
          <div class="backBtnVue" onClick={() => history.push("/")}>
            <KeyboardArrowLeftRoundedIcon />
            <p class="backBtnTxt">Back</p>
          </div>
          {/* <div className="single_suport_title_container">
          <p className="single_suport_txt">Signing Up & KYC</p>
        </div> */}

          <div className="suport_bar">Leads Related</div>
          {/* mobile */}
          <div className="mobile">
            <div className="main_col">
              <div className=" col_one">
                <p className="col_one_text">
                  1. Leads can be added in two formats{" "}
                </p>
                <p
                // className="col_one_subtext"
                // style={{ textTransform: "uppercase" }}
                >
                  {">"}{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    Individual leads
                  </span>
                </p>
                <img
                  className="individualleads"
                  style={{ marginTop: "10px" }}
                  src={individualleads}
                  alt="..."
                />
              </div>
              <div className=" col_two ">
                <p className="col_two_text mb-0">
                  {">"} Bulk data in the form of files (any format) that contain
                  individual leads themselves.They can also take a snap of any
                  physical document present & upload the same.
                </p>
                <img className="individualleads" src={BulkLeads} alt="..." />
              </div>
            </div>
            <div className="col_main mb-3">
              <p
                className="col_main_text mb-0"
                style={{ padding: "4px 16px 0px 16px" }}
              >
                2. Here the partner can view timely updates related to their
                leads & overview of how much commission a partner will
                eventually earn once there is closure on the lead and
                transaction is completed.
              </p>
              <div className="main_col">
                <div className=" col_one">
                  <img
                    className="individualleads2"
                    style={{ marginTop: "5px" }}
                    src={LeadStatus}
                    alt="..."
                  />
                </div>
                <div className=" col_two ">
                  <img
                    className="individualleads2"
                    style={{ marginTop: "5px" }}
                    src={CommissionDetails}
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
          {/* web */}
          <div className="web">
            <div className="content_web">
              <p className="col_one_text">
                1. Leads can be added in two formats{" "}
              </p>
              <p className="col_one_subtext">{">"} Individual leads</p>
              <img className="web_img_one" src={AddHotLead} alt="..." />
              <p className="col_one_subtext web_textcenter">
                {">"} Bulk data in the form of files (any format) that contain
                individual leads themselves.They can also take a snap of any
                physical <br /> document present & upload the same.
              </p>
              <img src={AddBulk} className="web_img_two" alt="..." />
              <p className="col_one_text web_textcenter">
                2. Here the partner can view timely updates related to their
                leads & overview of how much commission a partner will
                eventually earn once there is closure on the lead and
                transaction is completed.
              </p>
              <img src={StagePaymentWeb} alt="..." className="web_img_one" />
            </div>
          </div>
          {/* web */}
          <div className="suport_footer_content">
            <p className="text_help">
              Need more help? Our support team members are ready to help you
            </p>
            <div className="help">
              <button className="call_now">
                <a href="tel:9632888477" target="_blank" className="call">
                  <img className="logosvg" src={call} alt="..." />
                  <span>Call now</span>
                </a>
              </button>
              <button className="logos_whatsapp_icon">
                <a
                  href="https://wa.me/919632888477?text=I%27m%20interested%20in%20Cendrol%20Construction"
                  target="_blank"
                  className="call"
                >
                  <img
                    className="logosvg"
                    src={logos_whatsapp_icon}
                    alt="..."
                  />
                  <span className="chatwith">Chat with us</span>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Signingkyc;
