import React, { useEffect, useState } from "react";
import "./home.css";
import Wave from "../Assets/Img/Wave.png";
import Signing_Up_KYC from "../Assets/Img/Signing_Up_KYC.png";
import LeadsReleted from "../Assets/Img/LeadsReleted.svg";
import GeneralQuery from "../Assets/Img/GeneralQuery.png";
import SuportCard from "./ReusableComponents/SuportCard";
import Security from "../Assets/Img/Security.png";
import ChatWithUs from "../Assets/Img/ChatWithUs.png";
import CallNow from "../Assets/Img/CallNow.png";
import { Link, useHistory } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Footer from "./Navbar/Footer";
import TicketCard from "./ReusableComponents/TicketCard";
import Modal from "@mui/material/Modal";
import TicketPopup from "./ReusableComponents/TicketPopup";
import axiosClientInterceptors from "../Component/ReusableComponents/axiosClientInterceptors";
import CircularProgress from "@mui/material/CircularProgress";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_KEY,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_KEY,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_KEY,
  appId: process.env.REACT_APP_FIREBASE_APPID_KEY,
  measurementId: process.env.REACT_APP_MEASUREMENTID_KEY,
};

firebase.initializeApp(firebaseConfig);

const Home = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [singleTicket, setsingleTicket] = useState([]);
  const [NameEmail, setNameEmail] = useState("");
  const history = useHistory();
  const [loader, setloader] = useState(false);
  const getAllTickets = async () => {
    // console.log("formData", formData);

    const res = await axiosClientInterceptors.post(`/get-all-tickets`);
    setsingleTicket(res.data.result.listofTickets);
    setloader(false);
    console.log(res.data.result.listofTickets);
  };
  const getnameEmail = async (data) => {
    const response = await axiosClientInterceptors.get(
      `/get-partner-details?partner_id=${data}`
    );
    setNameEmail(response.data.result);
  };

  useEffect(async () => {
    setloader(true);
    window.scroll({
      top: 0,
    });
    window?.ReactNativeWebView?.postMessage(JSON.stringify({ screen: "home" }));
    const query = new URLSearchParams(window.location.search);
    const token = query.get("aakp");
    const _id = query.get("_id");
    const env = query.get("env");

    function wait(timeout) {
      if (token) {
        localStorage.setItem("supportToken", token);
      }
      if (_id) {
        localStorage.setItem("supportId", _id);
      }
      if (env) {
        localStorage.setItem("environment", env);
      }
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    }

    wait(1000).then(
      () => getnameEmail(localStorage.getItem("supportId") || _id),
      getAllTickets()
    );
  }, []);
  useEffect(() => {
    firebase.analytics().logEvent("Support Home Screen", { from: "web" });
  }, []);

  return (
    <div>
      <Navbar />

      <div className="support_home" style={{ position: "relative" }}>
        {/* <p className="backbutton">back</p> */}
        <div className="support_home_container">
          <div className="support_flex_container">
            <div>
              <p className="ui_container_main_text mb-0">Hi Partner</p>
              <p className="ui_container_main_text">
                how can we help you today ?
              </p>
            </div>
            <img src={Wave} alt=".." className="wave" />
          </div>
          <p className="ui_container_main_subtext">
            Need help with something? Our support team is here for you!
          </p>
        </div>
        <div className="tabs_container">
          {singleTicket?.length !== 0 && (
            <div className="titleticket">
              <p className="mb-0 YourTickets">Your Tickets</p>
              <p
                className="mb-0 YourTicketslink"
                onClick={() => history.push("/AllTicket")}
              >
                view all tickets
              </p>
            </div>
          )}
          {loader ? (
            <div className="dataaloader my-2">
              <CircularProgress size={20} />
            </div>
          ) : (
            <div class="parent mb-3">
              {singleTicket.slice(0, 6)?.map((val, i) => (
                <div
                  class="ticketCard"
                  onClick={() => history.push(`/SingleViewCard?id=${val._id}`)}
                >
                  <TicketCard
                    img={LeadsReleted}
                    status={val.ticket_status}
                    title={val.ticket_notes}
                    Time={val.updatedAt}
                    id={val._id}
                    TicketID={val.ticket_id}
                    subtext="Add leads - Lead Status - Commission "
                  />
                </div>
              ))}
            </div>
          )}
          {/* gap */}
          <div class="parent">
            <div class="Signing_Up_KYC">
              {/* 1st */}
              <Link to="/Signingkyc" className="link_tag">
                <SuportCard
                  img={Signing_Up_KYC}
                  title=" Leads Related"
                  subtext="Add leads - Lead Status - Commission "
                />
              </Link>
            </div>
            <div className="GeneralQuery">
              {/* 2nd */}
              <Link to="/GeneralQuery" className="link_tag">
                <SuportCard
                  img={GeneralQuery}
                  title="Payment Related "
                  subtext="Stage Payment - Referral - Receipt"
                />
              </Link>
            </div>
            <div class="Security">
              {/* 3rd */}
              <Link to="/Security" className="link_tag">
                <SuportCard
                  img={Security}
                  title="Security"
                  subtext="Privacy Policy - T&Cs - About"
                />
              </Link>
            </div>
            <div
              class="Chat_With_Us "
              style={{ cursor: "pointer" }}
              onClick={handleOpen}
            >
              {" "}
              {/* <a
                href={`https://wa.me/919164277477`}
                title="919164277477"
                id="whatsapp"
                target="_blank"
                className="link_tag"
              > */}
              <SuportCard
                img={Signing_Up_KYC}
                title="Create Support Ticket"
                subtext="Contact our support team right away!"
              />
              {/* </a> */}
            </div>
            <Modal
              open={open}
              className="popupbackground"
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <TicketPopup
                NameEmail={NameEmail}
                getAllTickets={getAllTickets}
                handleClose={handleClose}
              />
            </Modal>
            <div class="CallNow">
              <a
                id="phone"
                target="_blank"
                href={`tel:+919632888477`}
                className="link_tag"
              >
                <SuportCard
                  img={CallNow}
                  title="Call Now"
                  subtext="Contact our support team "
                />
              </a>
            </div>
            <div class="div6"></div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
