import React from "react";
import "../home.css";

const SuportCard = ({ img, title, subtext, imgwidth }) => {
  return (
    <div className="Signing_Up_subKYC">
      <img src={img} alt="..." />
      <div>
        <p className="Signing_Up_KYC_text">{title}</p>
        <p className="Signing_Up_KYC_subtext">{subtext}</p>
      </div>
    </div>
  );
};

export default SuportCard;
